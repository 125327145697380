/**
 *  jsonrpc - SMS mobile number verification

    krsms.auth - request to send out a PIN via SMS
    krsms.cnfm - reqeust to verify the PIN via form input
 */

var generic = generic || {};
var site = site || {};
var rb = rb || {};
site.sms = site.sms || {};
rb.error_messages = rb.error_messages || {};

var sms_processing_msg = "처리중...";
var sms_error_msg = "오류발생";
var sms_sentpin_msg = "인증번호 발송완료";
var sms_verifiedpin_msg = "인증완료";
var ipLockedMsg = rb.error_messages.sms_auth_ip_locked || sms_error_msg;
var isSmsAuthReqBtnDisabled = false;

function errorSMS (r) {
    var result_error = r.getError();

    console.log(result_error);
}

function msgShow(id, msg, type) {
    if (type == "error") {
        jQuery('#' + id).html("<span class='sms_msg_error'>" + msg + "</span>");
    } else {
        jQuery('#' + id).html("<span class='sms_msg_ok'>" + msg + "</span>");
    }
}

function msgHide(id) {
    jQuery('#' + id).html("");
}

/* Initialize */
jQuery(function() {

    var mobile_sms_prefix = "mobile_sms_prefix";
    var sms_prefix = jQuery('#' + mobile_sms_prefix).val();

    if (sms_prefix) {
        // i.e. "form--registration_short--field--"
        var area_code = sms_prefix + "MOBILE_AREA_CODE";
        var part1 = sms_prefix + "MOBILE_PART1";
        var part2 = sms_prefix + "MOBILE_PART2";
        var mobile_pin = sms_prefix + "MOBILE_PIN";
        var mobile_seq = sms_prefix + "MOBILE_SEQ";
        var mobile_number = sms_prefix + "MOBILE_NUMBER";
        var mobile_status = sms_prefix + "MOBILE_STATUS";
        var mobile_bypass = sms_prefix + "MOBILE_BYPASS";
        var phone1 = sms_prefix + "PHONE1";
        var phone1_type = sms_prefix + "PHONE1_TYPE";

        jQuery('#' + mobile_pin).prop('disabled', true);
    
        // buttons
        var btn_request_pin = "sms_request_pin";
        var btn_verify_pin = "sms_verify_pin";
    
        // msg
        var sms_request_msg = "sms_request_msg";
        var sms_verify_msg = "sms_verify_msg";
    
        // PIN request
        jQuery('#' + btn_request_pin).on('click', function() {

            if (isSmsAuthReqBtnDisabled) {
                return false;
            }

            var m_num = jQuery('#' + area_code).val() + jQuery('#' + part1).val() + jQuery('#' + part2).val();
            var m_num_display = jQuery('#' + area_code).val() + "-" + jQuery('#' + part1).val() + "-" + jQuery('#' + part2).val();
            var params = {
                'mobile': m_num
            };
    
            // reset
            jQuery('#' + mobile_number).val('');
            jQuery('#' + mobile_pin).val('');
            jQuery('#' + mobile_seq).val('');
            jQuery('#' + mobile_status).val('0');
            jQuery('#' + phone1).val('');
            jQuery('#' + phone1_type).val('');

            jQuery('#' + area_code).prop('disabled', true);
            jQuery('#' + part1).prop('disabled', true);
            jQuery('#' + part2).prop('disabled', true);
            jQuery('#' + mobile_pin).prop('disabled', true);
    
            msgShow(sms_request_msg, sms_processing_msg);
            msgHide(sms_verify_msg);
    
            var id = generic.jsonrpc.fetch({
                method : 'krsms.auth',
                params : [params],
                onSuccess: function(r) {
                    var result_value = r.getValue();
    
                    if (! result_value) {
                        jQuery('#' + area_code).prop('disabled', false);
                        jQuery('#' + part1).prop('disabled', false);
                        jQuery('#' + part2).prop('disabled', false);

                        console.log("* Invalid SMS Auth result");
                        msgShow(sms_request_msg, sms_error_msg, "error");
                    } else {
                        var return_code = result_value.code;
                        var return_seq = result_value.resSeq;
    
                        if (return_code === 'PIN_SENT') {
                            // sent pin with seq successfully
                            jQuery('#' + mobile_seq).val(return_seq);
                            jQuery('#' + mobile_number).val(m_num_display);
                            jQuery('#' + phone1).val(m_num_display);

                            jQuery('#' + mobile_pin).prop('disabled', false);

                            msgShow(sms_request_msg, sms_sentpin_msg);
                            // console.log("* SMS Auth PIN sent to: " + m_num + " - " + return_seq);
                            if (result_value.disableBtn) {
                                jQuery('#' + area_code).prop('disabled', true);
                                jQuery('#' + part1).prop('disabled', true);
                                jQuery('#' + part2).prop('disabled', true);
                                jQuery('#' + btn_request_pin).addClass('disabled');
                                jQuery('#' + btn_request_pin).css('pointer-events', 'none');
                                isSmsAuthReqBtnDisabled = true;
                                setTimeout(function () {
                                    jQuery('#' + btn_request_pin).removeClass('disabled');
                                    jQuery('#' + btn_request_pin).css('pointer-events', 'auto');
                                    jQuery('#' + area_code).prop('disabled', false);
                                    jQuery('#' + part1).prop('disabled', false);
                                    jQuery('#' + part2).prop('disabled', false);
                                    isSmsAuthReqBtnDisabled = false;
                                }, result_value.enableBtnDuration ? result_value.enableBtnDuration : 60000);
                            }
                        } else if (return_code === 'IP_LOCKED') {
                            jQuery('#' + area_code).prop('disabled', false);
                            jQuery('#' + part1).prop('disabled', false);
                            jQuery('#' + part2).prop('disabled', false);
                            msgShow(sms_request_msg, ipLockedMsg, 'error');
                        } else {
                            // error from provider
                            jQuery('#' + area_code).prop('disabled', false);
                            jQuery('#' + part1).prop('disabled', false);
                            jQuery('#' + part2).prop('disabled', false);

                            console.log("* SMS Auth failure code: " + return_code);
                            msgShow(sms_request_msg, sms_error_msg, "error");
                        }
                    }
                },
                onFailure: function(r) {
                    jQuery('#' + area_code).prop('disabled', false);
                    jQuery('#' + part1).prop('disabled', false);
                    jQuery('#' + part2).prop('disabled', false);

                    console.log("* SMS Auth failure");
                    errorSMS(r);
                    msgShow(sms_request_msg, sms_error_msg, "error");
                }
            });
        });
    
        // PIN verify
        jQuery('#' + btn_verify_pin).on('click', function() {
            var m_num = jQuery('#' + area_code).val() + jQuery('#' + part1).val() + jQuery('#' + part2).val();
            var p_num = jQuery('#' + mobile_pin).val();
            var s_num = jQuery('#' + mobile_seq).val();
            var params = {
                'mobile': m_num,
                'pin': p_num,
                'res_seq': s_num
            };

            jQuery('#' + mobile_pin).prop('disabled', true);
    
            msgShow(sms_verify_msg, sms_processing_msg);
    
            var id = generic.jsonrpc.fetch({
                method : 'krsms.cnfm',
                params : [params],
                onSuccess: function(r) {
                    var result_value = r.getValue();
    
                    if (! result_value) {
                        jQuery('#' + area_code).prop('disabled', false);
                        jQuery('#' + part1).prop('disabled', false);
                        jQuery('#' + part2).prop('disabled', false);
                        jQuery('#' + mobile_pin).prop('disabled', false);

                        console.log("* Invalid SMS Verify result");
                        msgShow(sms_verify_msg, sms_error_msg, "error");
                    } else {
                        var return_code = result_value.code;
                        var return_seq = result_value.resSeq;

                        jQuery('#' + area_code).prop('disabled', false);
                        jQuery('#' + part1).prop('disabled', false);
                        jQuery('#' + part2).prop('disabled', false);
    
                        if (return_code === 'PIN_CONFIRMED') {
                            // sent pin with seq successfully
                            jQuery('#' + mobile_seq).val(return_seq);
                            jQuery('#' + mobile_status).val("1");
                            jQuery('#' + phone1_type).val("2");
                            console.log("* SMS Verify success!");
                            msgShow(sms_verify_msg, sms_verifiedpin_msg);
                        } else {
                            // error from provider
                            jQuery('#' + mobile_pin).prop('disabled', false);

                            console.log("* SMS Verify failure code: " + return_code);
                            msgShow(sms_verify_msg, sms_error_msg, "error");
                        }
                    }
                },
                onFailure: function(r) {
                    jQuery('#' + area_code).prop('disabled', false);
                    jQuery('#' + part1).prop('disabled', false);
                    jQuery('#' + part2).prop('disabled', false);
                    jQuery('#' + mobile_pin).prop('disabled', false);

                    console.log("* SMS Verify failure");
                    errorSMS(r);
                    msgShow(sms_verify_msg, sms_error_msg, "error");
                }
            });
        });
    
        // Bypass
        jQuery('#' + mobile_bypass).on('click', function() {
      var mobile_num = jQuery('#' + area_code).val() + '-' + jQuery('#' + part1).val() + '-' + jQuery('#' + part2).val();

      if (jQuery('#' + area_code).val() && jQuery('#' + part1).val() && jQuery('#' + part2).val()) {
        jQuery('#' + mobile_status).val('1');
        jQuery('#' + phone1_type).val('2');
        jQuery('#' + mobile_number).val(mobile_num);
      }
            if (jQuery('#' + mobile_bypass).prop('checked')) {

                jQuery('#' + area_code).prop('disabled', true);
                jQuery('#' + part1).prop('disabled', true);
                jQuery('#' + part2).prop('disabled', true);
                jQuery('#' + mobile_pin).prop('disabled', true);
            } else {

                jQuery('#' + area_code).prop('disabled', false);
                jQuery('#' + part1).prop('disabled', false);
                jQuery('#' + part2).prop('disabled', false);
                jQuery('#' + mobile_pin).prop('disabled', false);
            }
        });
    }

	  //The tout registration part
	  var mobile_sms_tout_prefix = "mobile_sms_prefix_tout";
    var sms_tout_prefix = jQuery('#' + mobile_sms_tout_prefix).val();

    if (sms_tout_prefix) {
        // i.e. "form--registration_short--field--"
        var t_area_code = sms_tout_prefix + "MOBILE_AREA_CODE";
        var t_part1 = sms_tout_prefix + "MOBILE_PART1";
        var t_part2 = sms_tout_prefix + "MOBILE_PART2";
        var t_mobile_pin = sms_tout_prefix + "MOBILE_PIN";
        var t_mobile_seq = sms_tout_prefix + "MOBILE_SEQ";
        var t_mobile_number = sms_tout_prefix + "MOBILE_NUMBER";
        var t_mobile_status = sms_tout_prefix + "MOBILE_STATUS";
        var t_mobile_bypass = sms_tout_prefix + "MOBILE_BYPASS";
        var t_phone1 = sms_tout_prefix + "PHONE1";
        var t_phone1_type = sms_tout_prefix + "PHONE1_TYPE";

        jQuery('#' + t_mobile_pin).prop('disabled', true);
    
        // buttons
        var t_btn_request_pin = "sms_request_pin_tout";
        var t_btn_verify_pin = "sms_verify_pin_tout";
    
        // msg
        var t_sms_request_msg = "sms_request_msg_tout";
        var t_sms_verify_msg = "sms_verify_msg_tout";
    
        // PIN request
        jQuery('#' + t_btn_request_pin).on('click', function() {
            var m_num = jQuery('#' + t_area_code).val() + jQuery('#' + t_part1).val() + jQuery('#' + t_part2).val();
            var m_num_display = jQuery('#' + t_area_code).val() + "-" + jQuery('#' + t_part1).val() + "-" + jQuery('#' + t_part2).val();
            var params = {
                'mobile': m_num
            };
    
            // reset
            jQuery('#' + t_mobile_number).val('');
            jQuery('#' + t_mobile_pin).val('');
            jQuery('#' + t_mobile_seq).val('');
            jQuery('#' + t_mobile_status).val('0');
            jQuery('#' + t_phone1).val('');
            jQuery('#' + t_phone1_type).val('');

            jQuery('#' + t_area_code).prop('disabled', true);
            jQuery('#' + t_part1).prop('disabled', true);
            jQuery('#' + t_part2).prop('disabled', true);
            jQuery('#' + t_mobile_pin).prop('disabled', true);
    
            msgShow(t_sms_request_msg, sms_processing_msg);
            msgHide(t_sms_verify_msg);
    
            var id = generic.jsonrpc.fetch({
                method : 'krsms.auth',
                params : [params],
                onSuccess: function(r) {
                    var result_value = r.getValue();
    
                    if (! result_value) {
                        jQuery('#' + t_area_code).prop('disabled', false);
                        jQuery('#' + t_part1).prop('disabled', false);
                        jQuery('#' + t_part2).prop('disabled', false);

                        console.log("* Invalid SMS Auth result");
                        msgShow(t_sms_request_msg, sms_error_msg, "error");
                    } else {
                        var return_code = result_value.code;
                        var return_seq = result_value.resSeq;
    
                        if (return_code === "PIN_SENT") {
                            // sent pin with seq successfully
                            jQuery('#' + t_mobile_seq).val(return_seq);
                            jQuery('#' + t_mobile_number).val(m_num_display);
                            jQuery('#' + t_phone1).val(m_num_display);

                            jQuery('#' + t_mobile_pin).prop('disabled', false);

                            msgShow(t_sms_request_msg, sms_sentpin_msg);
                            // console.log("* SMS Auth PIN sent to: " + m_num + " - " + return_seq);
                        } else {
                            // error from provider
                            jQuery('#' + t_area_code).prop('disabled', false);
                            jQuery('#' + t_part1).prop('disabled', false);
                            jQuery('#' + t_part2).prop('disabled', false);

                            console.log("* SMS Auth failure code: " + return_code);
                            msgShow(t_sms_request_msg, sms_error_msg, "error");
                        }
                    }
                },
                onFailure: function(r) {
                    jQuery('#' + t_area_code).prop('disabled', false);
                    jQuery('#' + t_part1).prop('disabled', false);
                    jQuery('#' + t_part2).prop('disabled', false);

                    console.log("* SMS Auth failure");
                    errorSMS(r);
                    msgShow(t_sms_request_msg, sms_error_msg, "error");
                }
            });
        });
    
        // PIN verify
        jQuery('#' + t_btn_verify_pin).on('click', function() {
            var p_num = jQuery('#' + t_mobile_pin).val();
            var s_num = jQuery('#' + t_mobile_seq).val();
            var params = {
                'pin': p_num,
                'res_seq': s_num
            };

            jQuery('#' + t_mobile_pin).prop('disabled', true);
    
            msgShow(t_sms_verify_msg, sms_processing_msg);
    
            var id = generic.jsonrpc.fetch({
                method : 'krsms.cnfm',
                params : [params],
                onSuccess: function(r) {
                    var result_value = r.getValue();
    
                    if (! result_value) {
                        jQuery('#' + t_area_code).prop('disabled', false);
                        jQuery('#' + t_part1).prop('disabled', false);
                        jQuery('#' + t_part2).prop('disabled', false);
                        jQuery('#' + t_mobile_pin).prop('disabled', false);

                        console.log("* Invalid SMS Verify result");
                        msgShow(t_sms_verify_msg, sms_error_msg, "error");
                    } else {
                        var return_code = result_value.code;
                        var return_seq = result_value.resSeq;

                        jQuery('#' + t_area_code).prop('disabled', false);
                        jQuery('#' + t_part1).prop('disabled', false);
                        jQuery('#' + t_part2).prop('disabled', false);
    
                        if (return_code === "PIN_CONFIRMED") {
                            // sent pin with seq successfully
                            jQuery('#' + t_mobile_seq).val(return_seq);
                            jQuery('#' + t_mobile_status).val("1");
                            jQuery('#' + t_phone1_type).val("2");
                            console.log("* SMS Verify success!");
                            msgShow(t_sms_verify_msg, sms_verifiedpin_msg);
                        } else {
                            // error from provider
                            jQuery('#' + t_mobile_pin).prop('disabled', false);

                            console.log("* SMS Verify failure code: " + return_code);
                            msgShow(t_sms_verify_msg, sms_error_msg, "error");
                        }
                    }
                },
                onFailure: function(r) {
                    jQuery('#' + t_area_code).prop('disabled', false);
                    jQuery('#' + t_part1).prop('disabled', false);
                    jQuery('#' + t_part2).prop('disabled', false);
                    jQuery('#' + t_mobile_pin).prop('disabled', false);

                    console.log("* SMS Verify failure");
                    errorSMS(r);
                    msgShow(t_sms_verify_msg, sms_error_msg, "error");
                }
            });
        });

        // Bypass
        jQuery('#' + t_mobile_bypass).on('click', function() {
      var mobile_num = jQuery('#' + t_area_code).val() + '-' + jQuery('#' + t_part1).val() + '-' + jQuery('#' + t_part2).val();

      if (jQuery('#' + t_area_code).val() && jQuery('#' + t_part1).val() && jQuery('#' + t_part2).val()) {
        jQuery('#' + t_mobile_status).val('1');
        jQuery('#' + t_phone1_type).val('2');
        jQuery('#' + t_mobile_number).val(mobile_num);
      }

      if (jQuery('#' + t_mobile_bypass).prop('checked')) {
                jQuery('#' + t_area_code).prop('disabled', true);
                jQuery('#' + t_part1).prop('disabled', true);
                jQuery('#' + t_part2).prop('disabled', true);
                jQuery('#' + t_mobile_pin).prop('disabled', true);
            } else {

                jQuery('#' + t_area_code).prop('disabled', false);
                jQuery('#' + t_part1).prop('disabled', false);
                jQuery('#' + t_part2).prop('disabled', false);
                jQuery('#' + t_mobile_pin).prop('disabled', false);
            }

        });
    }

    //To hide the Mobile Bypass Option on LIVE
    var host_url = window.location.host;
    if(host_url.indexOf('cliniquekorea.co.kr')!= -1) {
        jQuery('#'+t_mobile_bypass).hide();
        jQuery('label[for='+t_mobile_bypass+']').hide();
    }

});